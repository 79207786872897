import { useState, useEffect } from 'react'

export function useBeforeFirstRender (f) {
  const [hasRendered, setHasRendered] = useState(false)
  useEffect(() => setHasRendered(true), [hasRendered])
  if (!hasRendered) {
    f()
  }
}

/**
 * Returns 'scrollbars-loading' if it has not finished, 'scroller-native' if mobile or mac and 'scroller' if else.
 * @returns {string}
 */
export function checkScrollbars() {
  const [isMobileOrMac, setIsMobileOrMac] = useState('scrollbars-loading');

  useEffect(() => {
    const mobileOrMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setIsMobileOrMac(mobileOrMac ? 'scroller-native' : 'scroller');
  });

  return isMobileOrMac;
}
