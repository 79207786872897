import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components';
import Button from '../components/Button';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const ContactForm = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  >* {
    margin: 5px 0;
  }
  >*:nth-child(even) {
    margin: 5px 0 15px;
  }
  >input, textarea {
    padding: 12px 15px;
    background: var(--content-bg);
    border: 2px solid var(--input-border-dark);
    border-radius: 8px;
    box-sizing : border-box;
    box-shadow: var(--content-bg);
    transition: all .2s ease-in-out;
    font-size: 18px;
    resize: none;
    color: var(--text);
    &:focus {
      border: 2px solid #3FA9F5;
    }
  }
  >label {
    font-size: 20px;
  }
  >textarea {
    height: 150px;
  }
  @media screen and (max-width: 350px) {
    >input, textarea, label {
      font-size: 15px;
    }
  }
`

const ButtonWrapper = styled(Button)`
  padding: 10px 20px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 10px 50px;
    width: initial;
    margin: 5px auto;
  }
`

const ContactPage = () => (
  <Layout>
    <Root>
      <ContactForm>
        <label htmlFor="name">Name:</label>
        <input id="name" type="text" placeholder="Your name..." />
        <label htmlFor="email">Email:</label>
        <input id="email" type="email" placeholder="Your email..." />
        <label htmlFor="message">Message:</label>
        <textarea id="message" placeholder="Your message..." />
        <ButtonWrapper>Send</ButtonWrapper>
      </ContactForm>
    </Root>
  </Layout>
)

export default ContactPage
