import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Root = styled.div`
  background: var(--bg-darker);
  box-sizing: border-box;
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: ${props => props.hasMargin ? "40px" : "0"};
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
`

const SocialItem = styled.a`
  padding: 6px 6px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  box-shadow: var(--shadow-on-bg);
  margin: 0 5px;
  font-size: 40px;
  color: #fff;
  &.linkedin {
    background: #0077b5;
    &:hover {
      background: ${lighten(0.05, '#0077b5')};
    }
  }
  &.github {
    background: #333;
    &:hover {
      background: ${lighten(0.1, '#333')};
    }
  }
  &.gitlab {
    background: #fca326;
    &:hover {
      background: ${lighten(0.1, '#fca326')};
    }
  }
  &.bitbucket {
    background: #205081;
    &:hover {
      background: ${lighten(0.1, '#205081')};
    }
  }
  @media (min-width: 768px) {
    font-size: 45px;
  }
  @media (min-width: 992px) {
    font-size: 50px;
  }
`

const Copyright = styled.p`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  padding: 30px 0 20px;
  margin: 0;
  >span {
    color: var(--footer-year);
  }
`

const Footer = (props) => (
  <Root hasMargin={props.hasMargin}>
    <Links>
      <SocialItem target="_blank" href="https://www.linkedin.com/in/lorenzo-pinna-rodr%C3%ADguez-650962186/" className="linkedin">
        <FontAwesomeIcon icon={['fab', 'linkedin']} fixedWidth />
      </SocialItem>
      <SocialItem target="_blank" href="https://github.com/TheL0w3R" className="github">
        <FontAwesomeIcon icon={['fab', 'github']} fixedWidth />
      </SocialItem>
      <SocialItem target="_blank" href="https://gitlab.com/thel0w3r" className="gitlab">
        <FontAwesomeIcon icon={['fab', 'gitlab']} fixedWidth />
      </SocialItem>
      <SocialItem target="_blank" href="https://bitbucket.org/TheL0w3R/" className="bitbucket">
        <FontAwesomeIcon icon={['fab', 'bitbucket']} fixedWidth />
      </SocialItem>
    </Links>
    <Copyright><span>© {new Date().getFullYear()}</span> Lorenzo Pinna Rodríguez</Copyright>
  </Root>
)

export default Footer
