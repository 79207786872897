import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import Header from './Header'
import Footer from './Footer'
import { checkScrollbars } from './custom-hooks'

const Root = styled.div`
  padding-top: 60px;
  height: 100%;
`

const Content = styled.div`
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Container = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  flex: 1 1 0;
  height: auto;
  padding-top: 20px;
  @media (min-width: 768px) {
    padding-top: 40px;
  }
`

const Layout = (props) => {
  const customScrollbars = checkScrollbars();
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <>
          <Helmet
            title="lorenzopr."
            meta={[
              {
                name: 'description',
                content: 'Lorenzo Pinna Rodríguez, junior Web & Mobile developer from Gran Canaria.',
              },
              {
                name: 'keywords',
                content: 'web, mobile, android, ios, apple, google, sdk, java, javascript, php, gran, canaria, canarias, las palmas, palmas, junior, desarrollador, desarrollo, pagina, página, paginas, páginas, fullstack, angular, vue, react, portafolio, curriculum, trabajo, work, cv',
              },
            ]}>
            <html lang="en"/>
          </Helmet>
          <Header toggleTheme={toggleTheme} isDark={theme === 'dark'}/>
          <Root>
            <Content className={customScrollbars}>
              <Container>
                {props.children}
              </Container>
              <Footer hasMargin={props.bottomMargin === undefined ? true : props.bottomMargin}/>
            </Content>
          </Root>
        </>
      )}
    </ThemeToggler>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
